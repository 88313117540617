/* eslint-disable jsx-a11y/iframe-has-title */
// eslint-disable-next-line jsx-a11y/iframe-has-title
import React from "react";
import "./LeftNav.css";
import HeaderButtons from "./components/HeaderButtons";
import MainMenu from "./components/MainMenu";
import SocialLink from "./components/SocialLink";

export default function LeftNav() {
  return (
    <nav
      className="left-nav"
      style={{
        width: 280,
        marginRight: 24,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderButtons></HeaderButtons>
      <MainMenu></MainMenu>
      <SocialLink></SocialLink>
    </nav>
  );
}
