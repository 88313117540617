import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FormOpacityModal from "../components/FormOpacityModal";
import { useLoading } from "../contexts/LoadingContext";
import customAxios from "../utils/customAxios";
import "./TransactionHistory.css";

import TransactionTable from "../features/TransactionTable";
import CheckVerified from "../features/CheckVerified";
import { useCookies } from "react-cookie";

export default function TransactionHistory() {
  const [cookies] = useCookies(["verified"]);
  const verified = cookies["verified"]?.verified;

  return <div style={{ width: "100%" }}>{verified ? <TransactionTable></TransactionTable> : <CheckVerified></CheckVerified>}</div>;
}
