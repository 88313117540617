import React, { useEffect, useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../utils/firebase";
import { toast } from "react-toastify";
import { formatPhoneWithCountryCode } from "../utils/utils";
import { useLoading } from "../contexts/LoadingContext";

// var grecaptcha: any;

export default function useOtp(phoneNo: string, isHide = true) {
  const resentTime = 60;
  const [otpSent, setOtpSent] = useState(false);
  const [counter, setCounter] = useState(0);
  const [otpConfirmationResult, setOtpConfirmationResult] = useState<any>();
  const { setLoading } = useLoading();

  const verifyRecapcha = () => {
    if (!phoneNo) return;
    const verify = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          toast.success(
            `OTP đã được gửi tới số điện thoại ${
              isHide ? "0******" + phoneNo.slice(-3) : phoneNo
            }`
          );
          setOtpSent(true);
        },
      },
      auth
    );
    return verify;
  };

  const sendOtp = (e?: any) => {
    if(e) e.preventDefault();

    if (!phoneNo) {
      toast.error(`Số điện thoại không chính xác.`);
    }

    setOtpSent(true);
    setCounter(resentTime);
    const recaptchaVerifier = verifyRecapcha();
    if (recaptchaVerifier) {
      setLoading(true);
      signInWithPhoneNumber(
        auth,
        formatPhoneWithCountryCode(phoneNo),
        recaptchaVerifier
      )
        .then((result) => {
          setLoading(false);
          setOtpConfirmationResult(result);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const checkOtp = async (otp: string): Promise<string | false> => {
    let otpConfirm = false;
    let idToken: string | undefined = "";
    try {
      const result = await otpConfirmationResult?.confirm(otp);
      const user = result.user;
      otpConfirm = !!user;
    } catch (error) {
      return false;
    }

    if (!otpConfirm) return false;

    try {
      idToken = await auth.currentUser?.getIdToken(/* forceRefresh */ true);
    } catch (error) {}
    if (!idToken) {
      return false;
    }

    return idToken;
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      } else if (counter <= 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const reset = () => {
    setOtpSent(false);
    setCounter(0);
    setOtpConfirmationResult(undefined);
  };

  return { sendOtp, checkOtp, reset, otpSent, counter };
}
