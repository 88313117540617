import React, { useState } from "react";

import bgfb4rum from "../../../assets/LeftNav/bg-fb4rum.png";
import btnActive from "../../../assets/LeftNav/btn-active.png";
import btnDisable from "../../../assets/LeftNav/btn-disable.png";
import ImageButton from "../../../components/ImageButton";

export default function SocialLink() {
  const [isFbActive, setIsFbActive] = useState(true);
  const [isGrActive, setIsGrActive] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundImage: `url(${bgfb4rum})`,
        }}
      >
        <ImageButton
          imgsrc={isFbActive ? btnActive : btnDisable}
          onClick={() => {
            setIsFbActive(true);
            setIsGrActive(false);
          }}
          text="Facebook"
          style={{
            textStyle: {
              fontSize: 18,
              backgroundImage:
                "linear-gradient(180deg, #FFF7E0 0%, #FEEC95 97.4%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
            },
            containerStyle: { boxSizing: "border-box", height: 43, width: 140 },
            imgStyle: { height: 43, width: 140 },
          }}
        />
        <ImageButton
          imgsrc={isGrActive ? btnActive : btnDisable}
          onClick={() => {
            setIsFbActive(false);
            setIsGrActive(true);
          }}
          text="Diễn Đàn"
          style={{
            textStyle: {
              fontSize: 18,
              backgroundImage:
                "linear-gradient(180deg, #FFF7E0 0%, #FEEC95 97.4%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
              left: "20%",
              transform: "translate(0, -50%)",
            },
            containerStyle: {
              boxSizing: "border-box",
              height: 43,
              width: 140,
              padding: 0,
            },
            imgStyle: { height: 43, width: 140 },
          }}
        />
      </div>
      <div style={{ display: isFbActive ? "flex" : "none" }}>
        <iframe
          src={process.env.REACT_APP_FANPAGE_URL}
          width="280"
          height="130"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
      <div
        style={{
          display: isGrActive ? "flex" : "none",
          width: 280,
          height: 130,
          backgroundColor: "transparent",
        }}
      >
        Diễn đàn
      </div>
    </div>
  );
}
