import React, { useState } from "react";
import FormOpacityModal from "../components/FormOpacityModal";
import InputText from "../components/InputText";
import Label from "../components/Label";
import icon_shield from "../assets/icons/icon_shield.svg";
import { toast } from "react-toastify";
import Button from "../components/Button";
import { useLoading } from "../contexts/LoadingContext";
import useOtp from "../hooks/otpHook";
import { useCookies } from "react-cookie";
import {isDisableOTP} from "../utils/utils";
import customAxios from "../utils/customAxios";

export default function CheckVerified() {
  const [otp, setOtp] = useState("");
  const [cookies, setCookie] = useCookies(["tlbb-account-info", "verified"]);
  const { loading, setLoading } = useLoading();

  const { sendOtp, checkOtp, reset, otpSent, counter } = useOtp(
    cookies["tlbb-account-info"]?.phoneNo
  );

  const submit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      const request: {
        otp?: string;
      } = {
        otp: otp,
      };

      const resp = await customAxios.post("/user/check-transfer", request);
      if (resp.status === 200 && resp.data?.success) {
        setLoading(false);
        setCookie(
          "verified",
          {
            verified: 1,
          },
          { path: "/", sameSite: "strict", maxAge: 10 * 60 }
        );
      } else {
        setLoading(false);
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra!");
    }
    // Old way
    const idToken = ""
    if (isDisableOTP == false) {
      const idToken = await checkOtp(otp);
      if (!idToken) {
        setLoading(false);
        toast.error("Mã OTP không hợp lệ!");
      } else {
        setLoading(false);
        setCookie(
          "verified",
          {
            verified: 1,
          },
          { path: "/", sameSite: "strict", maxAge: 10 * 60 }
        );
      }
    }
  };

  return (
    <FormOpacityModal
      submit={submit}
      size="lg"
      title="Lịch sử nạp thẻ"
      breadcrumb={["Quản lý tài khoản", "Lịch sử nạp thẻ"]}
      style={{ marginTop: 0 }}
    >
      <div style={{ width: "100%", padding: 4, justifyContent: "center" }}>
        <div>
          <Label
            style={{
              color: "rgb(255, 255, 255)",
            }}
          >
            Bạn cần nhập mã OTP để xem nội dung này!!!
          </Label>
          <Label style={{
                color: "rgb(255, 255, 255)",
              }}><b>TLNT sử dụng Telegram để gửi OTP</b>. Vui lòng xem hướng dẫn lấy mã OTP bằng ứng dụng Telegram tại <a href="https://thienlongngutuyet.net/huong-dan-su-dung-otp-telegram/" style={{color: "white"}} target="_blank"><b>đây</b></a>.</Label>
            <Label style={{
                color: "rgb(255, 255, 255)",
              }}><i>Bấm vào nút <b>Nhận OTP</b> bên dưới để mở Telegram</i></Label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                position: "relative",
              }}
            >
              <InputText
                imgsrc={icon_shield}
                value={otp}
                type="text"
                onChange={(e: any) => setOtp(e.target.value)}
              />
              <button
                type="button"
                style={{
                  position: "absolute",
                  right: 12,
                  top: "50%",
                  transform: "translate(0, -50%)",
                  cursor: counter > 0 ? "unset" : "pointer",
                  padding: "4px 8px",
                  fontSize: 11,
                  background: counter > 0 ? "#717171" : "rgb(39, 174, 96)",
                  border: "1px solid rgba(255, 255, 255, 0.45)",
                  borderRadius: 4,
                  color: "#ddd",
                  opacity: 1,
                }}
                disabled={counter > 0}
                onClick={() => window.open("https://t.me/ThienLongNguTuyetBot", '_blank', 'noopener,noreferrer')}
              >
                {otpSent
                  ? "Gửi lại" + (counter > 0 ? ` (${counter})` : "")
                  : "Nhận OTP"}
              </button>
            </div>
          </div>
          <div id="recaptcha-container"></div>
        </div>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop: 30,
          }}
        >
          <Button size="md" onClick={submit}>
            Xác nhận
          </Button>
        </div>
      </div>
    </FormOpacityModal>
  );
}
