import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import ImageButton from "../components/ImageButton";
import btnBg from "../assets/LeftNav/btn-active.png";
import btnBg_disable from "../assets/LeftNav/btn-social-disable.png";
import icon_point from "../assets/LeftNav/image_26.svg";
import { useLoading } from "../contexts/LoadingContext";
import { toast } from "react-toastify";
import customAxios from "../utils/customAxios";
import "./AccountHeader.css";
import lamsathu from "../assets/mainModal/lamsathu.png";
import cuonglongloanvu from "../assets/mainModal/cuonglongloanvu.png";
import phuchoquyen from "../assets/mainModal/phuchoquyen.png";


export default function AccountHeader() {
  let navigate = useNavigate();
  const logout = (e: any) => {
    e.preventDefault();
    removeCookie("tlbb-account-info", { sameSite: "strict", path: "/" });
  };
  const [cookies, setCookie, removeCookie] = useCookies(["tlbb-account-info"]);
  const displayName = cookies?.["tlbb-account-info"]?.displayName;
  const { loading, setLoading } = useLoading();
  const [point, setPoint] = useState();
  // const { serverList } = useGetServerList();
  const [serverImg, setServerImage] = useState("");

  const getPoint = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const resp = await customAxios.get("/user/get-point");
      setLoading(false);
      if (resp.status === 200 && resp.data?.success) {
        setPoint(resp.data?.data);
      } else {
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra!");
    }
  };

  useEffect(() => {
    getPoint();
    const serverCode = cookies?.["tlbb-account-info"]?.serverName ?? ""
    switch (serverCode) {
      case 'cuonglongloanvu':
        setServerImage(cuonglongloanvu);
        break;
      case 'lamsathu':
        setServerImage(lamsathu);
        break;
      case 'phuchoquyen':
        setServerImage(phuchoquyen);
        break;
      default:
        break;
    }

  }, []);

  return (
    <div style={{
      width: 632,
      maxWidth: "100%",
    }}>
      <div style={{
        textAlign: "right",
        marginTop: 'auto',
        maxWidth: "98%",
        color: "white",
      }}>
        <img src={serverImg} alt={cookies?.["tlbb-account-info"]?.serverName ?? ""} height={80} />
        {/* Máy chủ hiện tại: <b>{cookies?.["tlbb-account-info"]?.serverName ?? ""}</b> */}
      </div>
      <div
        style={{
          flexFlow: "row-reverse",
          alignItems: "flex-end",
          display: "flex",
          maxWidth: "98%",
        }}
      >
        <ImageButton
          imgsrc={btnBg}
          text="Thoát"
          onClick={logout}
          style={{
            imgStyle: { height: 40 },
            textStyle: {
              fontSize: 14,
              fontWeight: "400",
              color: "#FFFFC6",
            },
            containerStyle: { height: 40, boxSizing: "border-box" },
          }}
        />
        <div
          style={{
            textShadow: "2px 2px 16px rgba(0, 0, 0, 0.8)",
            fontWeight: 400,
            fontSize: 14,
            padding: "13px 16px",
            textAlign: "left",
            backgroundImage: `url(${btnBg_disable})`,
            color: "#FFFFC6",
            height: 40,
            backgroundRepeat: "no-repeat",
            width: 170,
            boxSizing: "border-box",
            backgroundSize: "100% 104%",
            marginLeft: -1,
            marginRight: -1,
            backgroundPositionY: "top",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Số Bạc:&nbsp;</span>
          <img src={icon_point} alt="" />
          <span>&nbsp;{point}</span>
        </div>
        <div
          style={{
            textShadow: "2px 2px 16px rgba(0, 0, 0, 0.8)",
            fontWeight: "400",
            fontSize: 14,
            padding: "13px 16px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundImage: `url(${btnBg_disable})`,
            color: "#FFFFC6",
            height: 40,
            backgroundRepeat: "no-repeat",
            // width: 210,
            boxSizing: "border-box",
            backgroundSize: "100% 104%",
            marginLeft: -1,
            marginRight: -1,
            backgroundPositionY: "top",
          }}
        >
          <span className={"title-header"}>Xin chào:&nbsp;</span>
          <span style={{ textTransform: "uppercase", fontWeight: "500" }}>
            {displayName}
          </span>
        </div>
      </div>
    </div>
  );
}
