import React from "react";

export default function InputText(props: {
  placeholder?: string;
  defaultValue?: string;
  imgsrc?: string;
  autoComplete?: string;
  name?: string;
  value?: string;
  onChange?: any;
  type?: string;
  disabled?: any;
  style?: {
    containerStyle?: React.CSSProperties | undefined;
    textStyle?: React.CSSProperties | undefined;
    imgStyle?: React.CSSProperties | undefined;
  };
  onClick?: any;
  required?: boolean;
}) {
  return (
    <div style={{ marginTop: 8, marginBottom: 8, position: "relative" }}>
      {props?.imgsrc && (
        <img
          onClick={props?.onClick}
          src={props?.imgsrc}
          style={{
            ...{
              height: 20,
              width: 20,
              position: "absolute",
              boxSizing: "border-box",
              top: "50%",
              left: 10,
              transform: "translateY(-50%)",
            },
            ...props?.style?.imgStyle,
          }}
          alt=""
        />
      )}
      <input
        {...props}
        style={{
          display: "block",
          width: "100%",
          boxSizing: "border-box",
          padding: "6px 12px",
          border: "1px solid rgba(34, 49, 63, 0.3)",
          borderRadius: 4,
          height: 40,
          color: props?.disabled ? "rgba(255, 255, 255, 0.6)" : "#000",
          backgroundColor: props?.disabled ? "rgba(50, 55, 61, 1)" : "white",
          paddingLeft: 35,
        }}
      />
    </div>
  );
}
