import React, { useState } from "react";
import ImageButton from "../../components/ImageButton";
import openBtn from "../../assets/LeftNav/open_btn.png";
import "./LeftNav.css";
import HeaderButtons from "./components/HeaderButtons";
import MainMenu from "./components/MainMenu";
import SocialLink from "./components/SocialLink";
import Backdrop from "./components/Backdrop";
import "./NavMobile.css";

export default function NavMobile() {
  const [isOpen, setIsOpen] = useState(false);
  const openWidth = 280;
  return (
    <>
      <Backdrop isOpen={isOpen} />
      <div
        id="navMobile"
        className="mobile_nav_container"
        style={{
          top: 0,
          left: isOpen ? 0 : -openWidth - 24,
        }}
      >
        <div className="menu_button_container">
          <div className={`menu-Button ${isOpen ? "open" : "close"}`}>
            <ImageButton
              onClick={() => setIsOpen(!isOpen)}
              imgsrc={openBtn}
              text={""}
              style={{
                containerStyle: { height: 27, overflow: "hidden" },
                textStyle: { display: "none" },
              }}
            ></ImageButton>
          </div>
        </div>
        <nav className="mobile_nav" style={{ width: openWidth }}>
          <HeaderButtons></HeaderButtons>
          <MainMenu></MainMenu>
          <SocialLink></SocialLink>
        </nav>
      </div>
    </>
  );
}
