import React from "react";
import HeaderBg from "../assets/mainModal/bg-modal-header.png";
import HomeIcon from "../assets/mainModal/ico-home.png";
import Logo from "../assets/mainModal/lg-big.png";

export default function FormOpacityModal(props: {
  submit?: (value: any | undefined) => void;
  title?: string;
  children?: any;
  errors?: string[];
  size?: "sm" | "md" | "lg";
  style?: any;
  titleStyle?: any;
  errorStyle?: any;
  breadcrumb?: string[];
}) {
  const {
    submit,
    title,
    children,
    style,
    titleStyle,
    errorStyle,
    errors,
    size = "sm",
    breadcrumb = [],
  } = props;

  const sizePx = size === "sm" ? 468 : size === "md" ? 632 : 912;

  let formTitle = title && (
    <div
      style={{
        ...{
          textAlign: "center",
          width: "100%",
          fontSize: "24px",
          fontWeight: "500",
          color: "#FDCC41",
        },
        ...titleStyle,
      }}
    >
      {title}
    </div>
  );

  const formHeader = breadcrumb?.length > 0 && (
    <div
      style={{
        width: sizePx,
        maxWidth: "95%",
        backgroundImage: `url(${HeaderBg})`,
        marginLeft: "auto",
        marginRight: "auto",
        height: 73,
        paddingLeft: 18,
        paddingRight: 18,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <img src={Logo} alt="logo" height={75} width={75} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignSelf: "stretch",
          marginTop: 14,
          marginBottom: 9,
        }}
      >
        <div
          style={{
            ...{
              width: "100%",
              fontSize: 20,
              fontWeight: "500",
              color: "#FDCC41",
              lineHeight: "20px",
            },
            ...titleStyle,
          }}
        >
          {title}
        </div>
        <div
          style={{
            ...{
              fontSize: 12,
              lineHeight: "20px",
              alignItems: "center",
              display: "flex",
              color: "white",
            },
            ...titleStyle,
          }}
        >
          <img src={HomeIcon} alt="home-icon" height={24} width={24} />
          {breadcrumb?.join(" > ")}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {formHeader}
      <form
        style={{
          ...{
            backgroundColor: "rgba(34, 49, 63, 0.8)",
            width: sizePx,
            maxWidth: "95%",
            padding: 32,
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            // borderRadius: "0 0 6px 6px",
            borderRadius: breadcrumb?.length > 0 ? "0 0 6px 6px" : 6,
            boxSizing: "border-box",
            color: "white",
          },
          ...style,
        }}
        onSubmit={submit}
      >
        {(!breadcrumb || breadcrumb?.length == 0) && formTitle}
        {errors && (
          <div style={errorStyle}>
            {errors?.map((error) => (
              <div
                style={{
                  color: "red",
                  fontSize: "0.9rem",
                  fontStyle: "italic",
                }}
              >
                * {error}
              </div>
            ))}
          </div>
        )}
        {children}
      </form>
    </>
  );
}
