import React from "react";
import accountMenuHeader from "../../../assets/LeftNav/block-tinhnang-hov.png";
import bgDragon1 from "../../../assets/LeftNav/bg-rong-1.png";
import bgDragon2 from "../../../assets/LeftNav/bg-rong-2.png";
import helpMenuHeader from "../../../assets/LeftNav/block-tinhnang-nhiemvu.png";
import { useNavigate } from "react-router-dom";
import MainMenuButton from "./MainMenuButton";
import MainMenuCategory from "./MainMenuCategory";

export default function MainMenu() {
  let navigate = useNavigate();
  return (
    <>
      <div>
        <MainMenuCategory
          bgImage={accountMenuHeader}
          text="Quản lý tài khoản"
        />
        <MainMenuButton
          text="Thông tin tài khoản"
          bgImage={bgDragon1}
          onClick={() => {
            navigate("/account/profile");
          }}
        />
        <MainMenuButton
          text="Lịch sử nạp thẻ"
          bgImage={bgDragon1}
          onClick={() => {
            navigate("/account/recharge-history");
          }}
        />
        <MainMenuButton
          text="Đổi mật khẩu"
          bgImage={bgDragon2}
          onClick={() => {
            navigate("/account/change-password");
          }}
        />
      </div>

      <div>
        <MainMenuCategory bgImage={helpMenuHeader} text="Thiên Long Bát Bộ" />

        <MainMenuButton
          text="Chức năng hỗ trợ nhân vật"
          bgImage={bgDragon2}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    </>
  );
}
