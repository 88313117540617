import React, { useState } from "react";
import Button from "../components/Button";
import FormOpacityModal from "../components/FormOpacityModal";
import InputText from "../components/InputText";
import { toast } from "react-toastify";
import customAxios from "../utils/customAxios";
import { useLoading } from "../contexts/LoadingContext";
import { useCookies } from "react-cookie";
import Label from "../components/Label";
import CheckboxChecked from "../assets/checkbox-checked.svg";
import CheckboxEmpty from "../assets/checkbox-empty.svg";
import icon_lock from "../assets/icons/icon_lock.svg";
import icon_lock2 from "../assets/icons/icon_lock2.svg";
import { ReactComponent as LockSVG } from "../assets/icons/lock2.svg";
import icon_shield from "../assets/icons/icon_shield.svg";
import icon_answer from "../assets/icons/icon_answer.svg";
import icon_question from "../assets/icons/icon_question.svg";
import { useNavigate } from "react-router-dom";
import InputSelect from "../components/InputSelect";
import useOtp from "../hooks/otpHook";
import useGetSercurityQuestions from "../hooks/useGetSercurityQuestions"; 
import {isDisableOTP} from "../utils/utils";
import "./Profile.css"




export default function ChangePassword() {
  //model
  const [password, setPassword] = useState("");
  const [newPassword, setNewpassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isUseOtp, setUseOtp] = useState(true);

  const { questions: listQuestion } = useGetSercurityQuestions();

  const [cookies] = useCookies(["tlbb-account-info"]);
  let navigate = useNavigate();

  //states
  const {
    sendOtp,
    checkOtp,
    reset: resetOtp,
    otpSent,
    counter,
  } = useOtp(cookies["tlbb-account-info"]?.phoneNo);

  const { loading, setLoading } = useLoading();
  const removeAccentsAnswer = answer.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const submit = async (e: any) => {
    e.preventDefault();
    if (loading) return;

    if (newPassword != confirmNewPassword) {
      toast.error("Xác nhận mật khẩu chưa chính xác!");
      return;
    }

    setLoading(true);

    try {
      const request: {
        password: string;
        newPassword: string;
        useOtp: boolean;
        fbIdToken?: string;
        question?: string;
        answer?: string;
      } = {
        password,
        newPassword,
        useOtp: isUseOtp,
      };
      if (isUseOtp) {
        const idToken = "";
        if (isDisableOTP == false) {
          const idToken = await checkOtp(otp);
          if (!idToken) {
            setLoading(false);
            toast.error("Mã OTP không hợp lệ!");
            return;
          }
        }
        // request.fbIdToken = idToken.toString();
        request.fbIdToken = otp;
      } else {
        if (!question) {
          setLoading(false);
          toast.error("Vui lòng chọn câu hỏi!");
          return;
        }
        if (!answer) {
          setLoading(false);
          toast.error("Vui lòng chọn câu trả lời!");
          return;
        }
        request.question = question;
        request.answer = removeAccentsAnswer;
      }

      const resp = await customAxios.post("/user/change-password", request);
      setLoading(false);
      if (resp.status === 200 && resp.data?.success) {
        toast.success("Đổi mật khẩu thành công!");
        setOtp("");
        resetOtp();
        setTimeout(() => navigate("/account/profile"), 1000);
      } else {
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra!");
    }
  };

  return (
    <FormOpacityModal
      submit={submit}
      size="md"
      style={{
        height: "auto",
        justifyContent: "center",
        alignItems: "center",
      }}
      title="Đổi mật khẩu"
      breadcrumb={["Quản lý tài khoản", "Đổi mật khẩu"]}
    >
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "flex-start",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            margin: -4,
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "50%", padding: 4 }} className={"profile-sdt"}>
            <Label>Mật khẩu mới</Label>
            <InputText
              autoComplete="off"
              type="password"
              imgsrc={icon_lock2}
              value={newPassword}
              onChange={(e: any) => setNewpassword(e.target.value)}
            />
          </div>
          <div style={{ width: "50%", padding: 4 }} className={"profile-sdt"}>
            <div style={{ content: "right" }}>
              <Label>Xác nhận mật khẩu mới</Label>
              <InputText
                autoComplete="off"
                type="password"
                imgsrc={icon_lock2}
                value={confirmNewPassword}
                onChange={(e: any) => setConfirmNewPassword(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            borderTop: "1px solid rgba(108, 123, 136, 1)",
            width: "100%",
            height: 1,
            marginTop: 24,
            marginBottom: 24,
          }}
        />

        <div
          style={{
            margin: -4,
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "50%", padding: 4 }} className={"profile-sdt"}>
            <Label>Mật khẩu hiện tại</Label>
            <InputText
              autoComplete="on"
              type="password"
              imgsrc={icon_lock}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <LockSVG style={{ marginRight: 5 }} />
              <span
                style={{
                  fontWeight: "500",
                  fontSize: 12,
                  lineHeight: "16px",
                  color: "rgba(189, 189, 189, 1)",
                }}
              >
                Quên mật khẩu?
              </span>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid rgba(108, 123, 136, 1)",
              width: "100%",
              height: 1,
              marginTop: 24,
              marginBottom: 24,
            }}
          />
          <div style={{ width: "100%", padding: 4 }}>
            <Label
              style={{
                marginLeft: 36,
                color: isUseOtp
                  ? "rgb(255, 255, 255)"
                  : "rgba(255, 255, 255, 0.45)",
              }}
            >
              Mã OTP
            </Label>
            <Label style={{
                marginLeft: 36,
                color: isUseOtp
                  ? "rgb(255, 255, 255)"
                  : "rgba(255, 255, 255, 0.45)",
              }}><b>TLNT sử dụng Telegram để gửi OTP</b>. Vui lòng xem hướng dẫn lấy mã OTP bằng ứng dụng Telegram tại <a href="https://thienlongngutuyet.net/huong-dan-su-dung-otp-telegram/" style={{color: "white"}} target="_blank"><b>đây</b></a>.</Label>
            <Label style={{
                marginLeft: 36,
                color: isUseOtp
                  ? "rgb(255, 255, 255)"
                  : "rgba(255, 255, 255, 0.45)",
              }}><i>Bấm vào nút <b>Nhận OTP</b> bên dưới để mở Telegram</i></Label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                alt=""
                src={isUseOtp ? CheckboxChecked : CheckboxEmpty}
                width={24}
                height={24}
                style={{ marginRight: 12, cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setUseOtp(true);
                }}
              />
              <div
                style={{
                  flex: 1,
                  position: "relative",
                }}
              >
                <InputText
                  imgsrc={icon_shield}
                  disabled={!isUseOtp}
                  value={otp}
                  type="text"
                  onChange={(e: any) => setOtp(e.target.value)}
                />
                <button
                  type="button"
                  style={{
                    position: "absolute",
                    right: 12,
                    top: "50%",
                    transform: "translate(0, -50%)",
                    cursor: counter > 0 ? "unset" : "pointer",
                    padding: "4px 8px",
                    fontSize: 11,
                    background: counter > 0 ? "#717171" : "rgb(39, 174, 96)",
                    border: "1px solid rgba(255, 255, 255, 0.45)",
                    borderRadius: 4,
                    color: "#ddd",
                    opacity: !isUseOtp ? 0.6 : 1,
                  }}
                  disabled={counter > 0 || !isUseOtp}
                  onClick={() => window.open("https://t.me/ThienLongNguTuyetBot", '_blank', 'noopener,noreferrer')}
                >
                  {otpSent
                    ? "Gửi lại" + (counter > 0 ? ` (${counter})` : "")
                    : "Nhận OTP"}
                </button>
              </div>
            </div>

            <div id="recaptcha-container"></div>
          </div>
          <div style={{
            width: "50%",
            padding: 4,
          }}
            className={"profile-sdt"}
          >
            <Label
              className={"label-question"}
              style={{
                marginLeft: 36,
                color: !isUseOtp
                  ? "rgb(255, 255, 255)"
                  : "rgba(255, 255, 255, 0.45)",
              }}
            >
              Câu hỏi bí mật
            </Label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                alt=""
                src={!isUseOtp ? CheckboxChecked : CheckboxEmpty}
                width={24}
                height={24}
                style={{ marginRight: 12, cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setUseOtp(false);
                }}
              />
              <div
                style={{
                  flex: 1,
                }}
              >
                <InputSelect
                  disabled={isUseOtp}
                  imgsrc={icon_question}
                  value={question}
                  onChange={(e: any) => setQuestion(e.target.value)}
                  style={{
                    width: "100%",
                    backgroundColor: isUseOtp
                      ? "rgb(50, 55, 61)"
                      : "rgb(255, 255, 255)",
                  }}
                >
                  <option value="">Chọn câu hỏi bí mật</option>
                  {listQuestion.map((q, index) => (
                    <option key={q.qsId} value={q.qsId}>
                      {q.qsText}
                    </option>
                  ))}
                </InputSelect>
              </div>
            </div>
          </div>
          <div style={{ width: "50%", padding: 4 }}
            className={"profile-sdt"}>
            <Label
              style={{
                color: !isUseOtp
                  ? "rgb(255, 255, 255)"
                  : "rgba(255, 255, 255, 0.45)",
              }}
            >
              Câu trả lời
            </Label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <InputText
                  imgsrc={icon_answer}
                  disabled={isUseOtp}
                  value={answer}
                  type="text"
                  onChange={(e: any) => setAnswer(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              borderTop: "1px solid transparent",
              width: "100%",
              height: 1,
              marginTop: 24,
              marginBottom: 24,
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Button onClick={submit} size="md">
            Đổi mật khẩu
          </Button>
          <div style={{ marginLeft: 10 }}>
            <Button
              type="button"
              onClick={() => navigate(-1)}
              style={{
                background: "#DD3D3D",
                padding: "6px 8px",
              }}
              size="md"
            >
              Quay lại
            </Button>
          </div>
        </div>
      </div>
    </FormOpacityModal>
  );
}
