import React from "react";
import downloadImg from "../../../assets/LeftNav/btn-playnow-nor 2.png";
import "./DownloadButton.css";

export default function DownloadButton() {
  return (
    <div className="download-btn">
      <img
        src={downloadImg}
        style={{ width: "130%", pointerEvents: "none" }}
        alt="btn"
      />
      {/* clickable */}
      <div
        className="clickable"
        onClick={(e) => {
          e.preventDefault();
          window.open(process.env.REACT_APP_HOMEPAGE_URL, "_blank");
        }}
      ></div>
    </div>
  );
}
