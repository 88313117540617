import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Routes, Route, Link, Outlet, useNavigate } from "react-router-dom";
import LeftNav from "../features/left-nav/LeftNav";
import AccountHeader from "../features/AccountHeader";
import "./Account.css";
import NavMobile from "../features/left-nav/NavMobile";

export default function Account() {
  const [cookies, setCookie, removeCookie] = useCookies(["tlbb-account-info"]);
  let navigate = useNavigate();
  useEffect(() => {
    if (
      !cookies["tlbb-account-info"]?.token ||
      cookies["tlbb-account-info"]?.token === ""
    ) {
      navigate("/login", { replace: true });
    }
  }, [cookies?.["tlbb-account-info"]?.token, navigate]);
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          maxWidth: 960,
          height: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {cookies?.["tlbb-account-info"]?.token && (
          <>
            <NavMobile />
            <LeftNav />
            <main
              className="main-modal-wrapper"
              style={{
                flex: 1,
                width: "100%",
                maxWidth: 636,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AccountHeader></AccountHeader>
              <Outlet />
            </main>
          </>
        )}
      </div>
    </div>
  );
}
