import { useState } from "react";

export function formatPhoneWithCountryCode(phoneNo: string) {
  if (!phoneNo) return phoneNo;

  if (phoneNo.startsWith("0")) {
    phoneNo = "+84" + phoneNo.substring(1);
  }

  return phoneNo;
}

export let isDisableOTP = true;
