import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import InputText from "../components/InputText";
// import axios from "axios";
import { useCookies } from "react-cookie";
import FormOpacityModal from "../components/FormOpacityModal";
import { useLoading } from "../contexts/LoadingContext";
import { toast } from "react-toastify";
import customAxios from "../utils/customAxios";
import icon_account from "../assets/icons/icon_account.svg";
import icon_lock from "../assets/icons/icon_lock.svg";
import icon_lock2_white from "../assets/icons/icon_lock2_white.svg";
import lg_big from "../assets/mainModal/lg-big.png";
import InputSelect from "../components/InputSelect";
import icon_question from "../assets/icons/icon_question.svg";
import useGetServerList from "../utils/ServerList";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const { loading, setLoading } = useLoading();
  const { serverList: serverList, defaultSelected } = useGetServerList();
  const [server, setServer] = useState(defaultSelected);

  const [cookies, setCookie] = useCookies(["tlbb-account-info"]);
  let navigate = useNavigate();
  useEffect(() => {
    if (
      cookies["tlbb-account-info"]?.token &&
      cookies["tlbb-account-info"]?.token !== ""
    ) {
      navigate("/");
    }
  }, [cookies]);

  const validate = () => {
    const newErrors = [];
    if (!username) newErrors.push("Bạn chưa nhập Tài khoản");
    if (!password) newErrors.push("Bạn chưa nhập Mật khẩu");
    setErrors(newErrors);
    return newErrors;
  };

  const submit = async (e: any) => {
    e.preventDefault();

    //validate trước khi submit
    const validateError = validate();
    if (validateError.length > 0) return;

    try {
      setLoading(true);
      const resp = await customAxios.post("/authentication/login", {
        username,
        password,
        server
      });
      setLoading(false);

      if (resp.status === 200 && resp.data?.token) {
        toast.success("Đăng nhập thành công!");

        setCookie(
          "tlbb-account-info",
          {
            token: resp.data?.token,
            displayName: resp.data?.displayName,
            username: resp.data?.username,
            email: resp.data?.username,
            phoneNo: resp.data?.phoneNo,
            serverName: serverList.find(s => s.qsId === server)?.code || ""
          },
          { path: "/", sameSite: "strict", maxAge: 2 * 60 * 60 }
        );
      } else {
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra!");
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <div style={{ width: "100%" }}>
        <img alt="logo" src={lg_big} style={{ margin: "0 auto", display: "flex", width: 287 }}></img>
      </div>
      <FormOpacityModal submit={submit} title="Đăng nhập" errors={errors} errorStyle={{ width: 256, marginLeft: "auto", marginRight: "auto" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: 256 }}>
            <label>Tài khoản</label>
            <InputText imgsrc={icon_account} name="username" value={username} onChange={(e: any) => setUsername(e.target.value)} />
            <label>Mật khẩu</label>
            <InputText imgsrc={icon_lock} type="password" name="password" value={password} onChange={(e: any) => setPassword(e.target.value)} />
            <label>Chọn máy chủ</label>
            {/* <div style={{ marginTop: 8, marginBottom: 8, position: "relative" }}> */}
            <InputSelect
              imgsrc={icon_question}
              value={server}
              onChange={(e: any) => {
                const serverId = Number(e.target.value)
                setServer(serverId)
              }}
              style={{ width: "100%" }}
              required
            >
              <option value="">Chọn máy chủ muốn chơi</option>
              {serverList.map((q, index) => (
                <option key={q.qsId} value={q.qsId}>
                  {q.qsText}
                </option>
              ))}
            </InputSelect>
            <Link
              style={{
                textAlign: "right",
                display: "block",
                color: "#BDBDBD",
                textDecoration: "none",
              }}
              to="/forgot-password"
            >
              <img
                style={{
                  top: 3,
                  position: "relative",
                  paddingRight: 5,
                  width: 20,
                }}
                src={icon_lock2_white}
                alt=""
              ></img>
              Quên mật khẩu?
            </Link>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: "8%" }}>
            <Button type="submit" style={{ width: 256 }}>
              Đăng nhập
            </Button>
          </div>
          <hr />
        </div>
        <p>
          Gia nhập cùng Thiên Long Ngũ Tuyệt!{" "}
          <Link to="/register" style={{ color: " #F78143", textDecoration: "none" }}>
            Đăng ký ngay
          </Link>
        </p>
      </FormOpacityModal>
    </div>
  );
}
