import axios from "../utils/customAxios";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../components/Button";
import FormOpacityModal from "../components/FormOpacityModal";
import InputText from "../components/InputText";
import Label from "../components/Label";
import { toast } from "react-toastify";
import { useLoading } from "../contexts/LoadingContext";
import { useNavigate } from "react-router-dom";
import icon_user_white from "../assets/icons/icon_user_white.svg";
import icon_phone_white from "../assets/icons/icon_phone_white.svg";
import icon_mail_white from "../assets/icons/icon_mail_white.svg";

export default function Profile() {
  const [userProfile, setUserProfile] = useState<{
    name: string;
    phoneNo: string;
    email: string;
  }>();

  const displayPhoneNo = userProfile?.phoneNo
    ? userProfile?.phoneNo?.slice(0, 3) +
      "*******" +
      userProfile?.phoneNo?.slice(-3)
    : "";

  const displayEmail = useCallback(() => {
    let email = "";
    if (userProfile?.email && userProfile?.email.length > 3) {
      let indexOfAt = userProfile?.email?.indexOf("@");
      const head = indexOfAt && indexOfAt !== -1 ? Math.min(3, indexOfAt) : 3;
      email += userProfile?.email?.slice(0, head);
      email += "*******";

      if (indexOfAt && indexOfAt != -1) {
        email += userProfile?.email?.slice(
          indexOfAt - userProfile.email.length
        );
      }
    }

    return email;
  }, [userProfile?.email]);

  const { loading, setLoading } = useLoading();
  let navigate = useNavigate();

  const getUserProfile = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const resp = await axios.get("/user/profile");
      setLoading(false);
      if (resp.status === 200 && resp.data?.success) {
        setUserProfile(resp.data.data);
      } else {
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra!");
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const handleClickChangePassword = () => {
    navigate("/account/change-password");
  };

  const handleClickChangeQuestion = ()=> {
    navigate("/account/change-question");
  };

  const handleClickEditProfile = () => {
    navigate("/account/edit-profile");
  };

  return (
    <FormOpacityModal
      size="md"
      submit={() => {}}
      title="Thông tin tài khoản"
      breadcrumb={["Quản lý tài khoản", "Thông tin tài khoản"]}
      style={{ marginTop: 0 }}
    >
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "flex-start",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: 16,
              lineHeight: "28px",
              color: "#FDCC41",
            }}
          >
            Thông tin cá nhân
          </div>
          <Button
            style={{ width: 154 }}
            type="button"
            onClick={handleClickEditProfile}
          >
            Chỉnh sửa thông tin
          </Button>
        </div>
        <div style={{ width: "100%" }}>
          <Label>Tên tài khoản</Label>
          <InputText
            imgsrc={icon_user_white}
            value={userProfile?.name || ""}
            disabled
          />
        </div>
        <div style={{ width: "50%" }} className={"profile-sdt"}>
          <Label>Số điện thoại</Label>
          <InputText
            imgsrc={icon_phone_white}
            value={displayPhoneNo}
            disabled
          />
        </div>
        <div style={{ width: "50%" }} className={"profile-email"}>
          <Label>Email</Label>
          <InputText imgsrc={icon_mail_white} value={displayEmail()} disabled />
        </div>

        <h3>Thông tin tài khoản</h3>
        <div style={{ display: "flex", width: "100%", alignItems: "baseline" }}>
          <Label style={{ width: 154 }}>Mật khẩu</Label>
          <Button
            style={{ width: 154 }}
            type="button"
            onClick={handleClickChangePassword}
          >
            Đổi mật khẩu
          </Button>
        </div>
        <div style={{ display: "flex", width: "100%", alignItems: "baseline" }}>
          <Label style={{ width: 154 }}>Câu hỏi bí mật</Label>
          <Button
            style={{ width: 154 }}
            type="button"
            onClick={handleClickChangeQuestion}
          >
            Đổi câu hỏi bí mật
          </Button>
        </div>
      </div>
    </FormOpacityModal>
  );
}
