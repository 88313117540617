import React from "react";
import "./Button.css";
export default function Button(
  props: {
    size?: "xs" | "md" | "lg";
    style?: React.CSSProperties;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  const { size = "md", style, ...others } = props;
  let sizePx: number | string = 0;
  switch (size) {
    case "xs":
      sizePx = 64;
      break;
    case "md":
      sizePx = 156;
      break;
    case "lg":
      sizePx = 256;
      break;
    default:
      sizePx = 156;
      break;
  }
  return (
    <div className="button">
      <button
        className="button"
        {...others}
        style={{
          ...{
            width: sizePx,
            display: "block",
            padding: "6px 8px",
            lineHeight: "20px",
            fontSize: 12,
            fontWeight: "600",
            opacity: others.disabled ? 0.4 : 1,
            background: "#F78143",
            border: "1px solid #F0562B",
            borderRadius: 4,
            color: "white",
          },
          ...style,
        }}
      ></button>
    </div>
  );
}
