import React from "react";
import ImageButton from "../../../components/ImageButton";

export default function MainMenuCategory(props: {
  text: string;
  bgImage: string;
}) {
  return (
    <ImageButton
      imgsrc={props?.bgImage}
      text={props?.text}
      style={{
        containerStyle: {
          cursor: "default",
        },
        textStyle: {
          fontSize: 14,
          top: "45%",
          left: "32%",
          transform: "translate(0, -50%)",
          backgroundImage: "linear-gradient(180deg, #FEEC95 0%, #F7CC56 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textShadow: "2px 2px 2px rgba(0, 0, 0, 0.6)",
        },
      }}
    />
  );
}
