import React, { useState } from "react";
import Button from "../components/Button";
import FormOpacityModal from "../components/FormOpacityModal";
import InputText from "../components/InputText";
import { toast } from "react-toastify";
import customAxios from "../utils/customAxios";
import { useLoading } from "../contexts/LoadingContext";
import { useCookies } from "react-cookie";
import Label from "../components/Label";
import CheckboxChecked from "../assets/checkbox-checked.svg";
import CheckboxEmpty from "../assets/checkbox-empty.svg";
import icon_lock from "../assets/icons/icon_lock.svg";
import icon_lock2 from "../assets/icons/icon_lock2.svg";
import icon_shield from "../assets/icons/icon_shield.svg";
import icon_phone from "../assets/icons/icon_phone.svg";
import lg_big from "../assets/mainModal/lg-big.png";
import { useNavigate } from "react-router-dom";
import useOtp from "../hooks/otpHook";
import InputSelect from "../components/InputSelect";
import icon_question from "../assets/icons/icon_question.svg";
import useGetServerList from "../utils/ServerList";
import {isDisableOTP} from "../utils/utils";

export default function ForgotPassword() {
  let navigate = useNavigate();
  //model
  const [account, setAccount] = useState("");
  const [newPassword, setNewpassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [otp, setOtp] = useState("");
  const [isUseOtp, setUseOtp] = useState(true);

  const [cookies] = useCookies(["tlbb-account-info"]);
  const { serverList: serverList, defaultSelected } = useGetServerList();
  const [server, setServerList] = useState(defaultSelected);

  const {
    sendOtp,
    checkOtp,
    reset: resetOtp,
    otpSent,
    counter,
  } = useOtp(phoneNo, false);
  const { loading, setLoading } = useLoading();

  const submit = async (e: any) => {
    e.preventDefault();
    if (loading) return;

    if (newPassword !== confirmNewPassword) {
      toast.error("Xác nhận mật khẩu chưa chính xác!");
      return;
    }
    if (!account || !newPassword || isUseOtp === undefined) {
      toast.error("Tài khoản là trường bắt buộc!");
      return;
    }
    if (!newPassword) {
      toast.error("Mật khẩu mới là trường bắt buộc!");
      return;
    }
    if (isUseOtp) {
      if (!phoneNo) {
        toast.error("Số điện thoại là trường bắt buộc!");
        return;
      }
      if (!otp) {
        toast.error("Otp là trường bắt buộc!");
        return;
      }
    }

    setLoading(true);
    const idToken = "";
    if (isDisableOTP == false) {
      const idToken = await checkOtp(otp);
      if (!idToken) {
        setLoading(false);
        toast.error("Mã OTP không hợp lệ!");
        return;
      }
    }

    try {
      const request: {
        username: string;
        newPassword: string;
        useOtp: boolean;
        phoneNo?: string;
        fbIdToken?: string;
        question?: string;
        answer?: string;
        server?: any;
      } = {
        username: account,
        newPassword,
        server: server,
        useOtp: isUseOtp,
      };
      if (isUseOtp) {
        request.fbIdToken = otp;
        request.phoneNo = phoneNo;
      }

      const resp = await customAxios.post(
        "/authentication/forgot-password",
        request
      );
      setLoading(false);
      if (resp.status === 200 && resp.data?.success) {
        toast.success("Đổi mật khẩu thành công!");
        // setOtp("");
        // resetOtp();
        setTimeout(() => navigate("/login"), 500);
      } else {
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra!");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        paddingBottom: 10,
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          alt="logo"
          src={lg_big}
          style={{ margin: "0 auto", display: "flex", width: 287 }}
        ></img>
      </div>
      <FormOpacityModal
        submit={submit}
        size="md"
        style={{
          height: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
        title="Quên mật khẩu"
      >
        <div
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-start",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
          <div
            style={{
              margin: -4,
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: "100%", padding: 4 }}>
              <Label>Tài khoản</Label>
              <InputText
                imgsrc={icon_lock}
                value={account}
                name="new-username"
                onChange={(e: any) => setAccount(e.target.value)}
              />
            </div>

            <div style={{ width: "50%", padding: 4 }}>
              <Label>Mật khẩu mới</Label>
              <InputText
                type="password"
                name="new-password"
                imgsrc={icon_lock2}
                value={newPassword}
                onChange={(e: any) => setNewpassword(e.target.value)}
              />
            </div>
            <div style={{ width: "50%", padding: 4 }}>
              <div style={{ content: "right" }}>
                <Label>Xác nhận mật khẩu mới</Label>
                <InputText
                  type="password"
                  imgsrc={icon_lock2}
                  value={confirmNewPassword}
                  onChange={(e: any) => setConfirmNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div style={{ width: "100%", padding: 4 }}>
              <div style={{ content: "right" }}>
                <Label>Chọn máy chủ</Label>
                {/* <div style={{ marginTop: 8, marginBottom: 8, position: "relative" }}> */}
                <InputSelect
                  imgsrc={icon_question}
                  value={server}
                  onChange={(e: any) => setServerList(e.target.value)}
                  style={{ width: "100%" }}
                  required
                >
                  <option value="">Chọn máy chủ đang chơi</option>
                  {serverList.map((q, index) => (
                    <option key={q.qsId} value={q.qsId}>
                      {q.qsText}
                    </option>
                  ))}
                </InputSelect>
              </div>
            </div>
          </div>

          <div
            style={{
              borderTop: "1px solid rgba(108, 123, 136, 1)",
              width: "100%",
              height: 1,
              marginTop: 24,
              marginBottom: 24,
            }}
          />

          <div
            style={{
              margin: -4,
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                width: "50%",
                padding: 4,
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <img
                alt=""
                src={isUseOtp ? CheckboxChecked : CheckboxEmpty}
                width={24}
                height={24}
                style={{ marginRight: 12, cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setUseOtp(true);
                }}
              />

              <Label
                fullWidth={false}
                onClick={() => setUseOtp(true)}
                style={{
                  color: isUseOtp
                    ? "rgb(255, 255, 255)"
                    : "rgba(255, 255, 255, 0.45)",
                }}
              >
                Xác thực OTP
              </Label>
            </div>

            {isUseOtp && (
              <div style={{ width: "100%", padding: 4 }}>
                <Label>Số điện thoại</Label>
                <InputText
                  imgsrc={icon_phone}
                  disabled={!isUseOtp}
                  value={phoneNo}
                  type="text"
                  onChange={(e: any) => setPhoneNo(e.target.value)}
                />
                <Label>Mã OTP</Label>
                <Label><b>TLNT sử dụng Telegram để gửi OTP</b>. Vui lòng xem hướng dẫn lấy mã OTP bằng ứng dụng Telegram tại <a href="https://thienlongngutuyet.net/huong-dan-su-dung-otp-telegram/" style={{color: "white"}} target="_blank"><b>đây</b></a>.</Label>
                <Label><i>Bấm vào nút <b>Nhận OTP</b> bên dưới để mở Telegram</i></Label>
                <div
                  style={{
                    flex: 1,
                    position: "relative",
                  }}
                >
                  <InputText
                    imgsrc={icon_shield}
                    disabled={!isUseOtp}
                    value={otp}
                    type="text"
                    onChange={(e: any) => setOtp(e.target.value)}
                  />
                  <button
                    type="button"
                    style={{
                      position: "absolute",
                      right: 12,
                      top: "50%",
                      transform: "translate(0, -50%)",
                      cursor: counter > 0 ? "unset" : "pointer",
                      padding: "4px 8px",
                      fontSize: 11,
                      background: counter > 0 ? "#717171" : "rgb(39, 174, 96)",
                      border: "1px solid rgba(255, 255, 255, 0.45)",
                      borderRadius: 4,
                      color: "#ddd",
                      opacity: !isUseOtp ? 0.6 : 1,
                    }}
                    disabled={counter > 0 || !isUseOtp || !phoneNo}
                    onClick={() => window.open("https://t.me/ThienLongNguTuyetBot", '_blank', 'noopener,noreferrer')}
                  >
                    {otpSent
                      ? "Gửi lại" + (counter > 0 ? ` (${counter})` : "")
                      : "Nhận OTP"}
                  </button>
                </div>
              </div>
            )}

            <div
              style={{
                borderTop: "1px solid transparent",
                width: "100%",
                height: 1,
                // marginTop: 24,
                marginBottom: 24,
              }}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button onClick={submit} size="md">
            Đổi mật khẩu
          </Button>
          <a
            onClick={() => navigate(-1)}
            style={{
              marginTop: 8,
              color: "#F78143",
              fontWeight: 600,
              fontSize: 14,
              lineHeight: "24px",
              cursor: "pointer",
            }}
          >
            Quay lại
          </a>
        </div>
      </FormOpacityModal>

      <div id="recaptcha-container"></div>
    </div>
  );
}
