import React from "react";

export default function Backdrop(props: { isOpen: boolean }) {
  return (
    <label
      htmlFor="btn"
      id="overlay"
      className="nav__overlay"
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: !props?.isOpen ? "none" : "block",
        animation: "fadeIn linear 0.2s",
        zIndex: 10,
      }}
    ></label>
  );
}
