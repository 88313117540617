import { useEffect, useState } from "react";

const useGetServerList = () => {
  const [serverList, setServer] = useState<
    {
      qsId: number;
      qsText: string;
      code: string;
      qsStatus: number;
    }[]
  >([]);
  const defaultSelected = 1

  const setHardcodeServerList = () => {
    setServer([
      {
        qsId: 1,
        qsText: "Phục Hổ Quyền",
        code: 'phuchoquyen',
        qsStatus: 1,
      },
      {
        qsId: 2,
        qsText: "Lam Sa Thủ",
        code: 'lamsathu',
        qsStatus: 1,
      },
    ]);
  };

  useEffect(() => {
    setHardcodeServerList();
  }, []);

  return { serverList, defaultSelected };
};

export default useGetServerList;
