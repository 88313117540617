
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "universal-cookie";
// import { removeLocalState } from "utils/localStorage";
// import { API_USER_REFRESH_TOKEN } from "constants/api";

class InstanceAxios {
  protected cookies: Cookies;
  protected axios: AxiosInstance;
  constructor() {
    this.cookies = new Cookies();
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      responseType: "json",
      withCredentials: false,
      headers: {
        Accept: "application/json",
      },
    });

    this.axios.interceptors.request.use((param: any) => {
      const token = this.cookies.get("tlbb-account-info")?.token;
      return {
        ...param,
        headers: {
          ...param.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          this.cookies.remove("tlbb-account-info");
          window.location.href = "/login";
        }

        return error.response;
      }
    );
  }

  public get(url: string, config?: AxiosRequestConfig) {
    return this.axios.get(url, config);
  }

  public fetch(url: string, params?: any) {
    return this.axios.get(url, { params });
  }

  public post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.axios.post(url, data, config);
  }

  public put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.axios.put(url, data, config);
  }

  public delete(url: string, config?: AxiosRequestConfig) {
    return this.axios.delete(url, config);
  }

  public patch(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.axios.patch(url, data, config);
  }
}
export default new InstanceAxios();
