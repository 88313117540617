import React, { useEffect, useState } from "react";
import FormOpacityModal from "../components/FormOpacityModal";
import { toast } from "react-toastify";
import customAxios from "../utils/customAxios";
import { useLoading } from "../contexts/LoadingContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import lg_big from "../assets/mainModal/lg-big.png";

export default function CompleteEmailConfirmRegister() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [message, setMessage] = useState("Đang xử lý...");
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    if (searchParams.get("code") && searchParams.get("id")) {
      unlockAccount();
    }
  }, [searchParams]);

  const unlockAccount = async () => {
    setLoading(true);
    const res = await customAxios.post("/authentication/complete-register", {
      emailConfirmCode: searchParams.get("code"),
      account: searchParams.get("id"),
      server: searchParams.get("server")
    });
    setLoading(false);
    if (res.status === 200 && res.data.success) {
      setMessage(
        "Xác thực email thành công, bạn có thể bắt đầu sử dụng tài khoản ngay bây giờ, chúc bạn có thời gian chơi game vui vẻ!"
      );
      toast.success("Hoàn tất đăng ký!");
    } else {
      setMessage(
        "Có lỗi xảy ra trong quá trình xác thực email, xin vui lòng liên hệ ban quản trị để biết thêm chi tiết"
      );
      toast.error(
        res.data?.messageList?.length
          ? res.data?.messageList[0]
          : "Có lỗi xảy ra!"
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          alt="logo"
          src={lg_big}
          style={{ margin: "0 auto", display: "flex", width: 150 }}
        ></img>
      </div>

      <FormOpacityModal title="Hoàn thành đăng ký">
        <div
          style={{
            marginRight: "auto",
            marginLeft: "auto",
            padding: 8,
            textAlign: "center",
          }}
        >
          {/* <div>{id}</div>
          <div>{code}</div> */}
          <label>{message}</label>
          <div style={{ marginTop: 20 }}>
            <label
              onClick={() => navigate("/login")}
              style={{
                cursor: "pointer",
                color: "rgba(253, 204, 65, 0.5)",
              }}
            >
              <i>Về trang đăng nhập</i>
            </label>
          </div>
        </div>
      </FormOpacityModal>
    </div>
  );
}
