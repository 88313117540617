import React from "react";

export default function InputSelect(props: any) {
  return (
    <div style={{ marginTop: 8, marginBottom: 8, position: "relative" }}>
      {props?.imgsrc && (
        <img
          src={props?.imgsrc}
          style={{
            ...{
              height: 20,
              width: 20,
              position: "absolute",
              boxSizing: "border-box",
              top: "50%",
              left: "3%",
              transform: "translateY(-50%)",
            },
          }}
          alt="btn"
        />
      )}
      <select
        {...props}
        style={{
          ...{
            border: "1px solid rgba(34, 49, 63, 0.3)",
            borderRadius: 4,
            display: "block",
            boxSizing: "border-box",
            height: 40,
            paddingLeft: 35,
            textAlign:"left"
          },
          ...props.style,
        }}
      ></select>
    </div>
  );
}
