import React, { useEffect, useState } from "react";
import FormOpacityModal from "../components/FormOpacityModal";
import Table from "../components/Table";
import "./Recharge.css";
import QRCode from "react-qr-code";
import customAxios from "../utils/customAxios";

export default function Recharge() {
  const getTransaction = async () => {
    const response = await customAxios.get("transaction");
    if (response.status == 200 && response.data?.success) {
      setCode(response.data?.data);
    }
  };
  useEffect(() => {
    getTransaction();
  }, []);
  const [code, setCode] = useState<string>();
  return (
    <FormOpacityModal
      size="md"
      submit={() => {}}
      title="Donate"
      breadcrumb={["Quản lý tài khoản", "Donate"]}
      style={{ marginTop: 0 }}
    >
      <div style={{ marginBottom: 20, width: "100%" }}>
        Chuyển khoản qua Momo với nội dung{" "}
        <span style={{ color: "rgb(253, 204, 65)" }}>{code}</span> để Donate vào server
        <br />
        Sau khi chuyển xong vui lòng liên hệ trực tiếp qua{" "}
        <a
          style={{ color: "rgb(253, 204, 65)" }}
          target='_blank'
          href="https://www.facebook.com/thienlongngutuyet/"
        >
          <b>Fanpage</b>
        </a>
      </div>
      <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
        <div className="qr-display">
          <div>
            <div></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {code && (
                <QRCode
                  value={`2|99|0989493399|Thien Long Ngu Tuyet|tlngutuyet@gmail.com|0|0|0|${code}|transfer_myqr`}
                />
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="exchange-rate" style={{ flex: 1 }}>
          <Table>
            <thead>
              <tr>
                <th>Mệnh giá</th>
                <th>Quy đổi</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>50.000 VNĐ</td>
                <td>50 Bạc</td>
              </tr>
              <tr>
                <td>100.000 VNĐ</td>
                <td>100 Bạc</td>
              </tr>
              <tr>
                <td>200.000 VNĐ</td>
                <td>200 Bạc</td>
              </tr>
              <tr>
                <td>500.000 VNĐ</td>
                <td>500 Bạc</td>
              </tr>
              <tr>
                <td>1.000.000 VNĐ</td>
                <td>1.000 Bạc</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </FormOpacityModal>
  );
}
