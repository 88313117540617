import React from "react";
import "../screens/TransactionHistory.css";

const Pagination = (props: { itemsPerPage: number; totalItems: number; paginate: any; currentPage: number; setCurrentPage: any; totalItem: number }) => {
  const pageNumbers = [];
  const { itemsPerPage, totalItems, paginate, currentPage, setCurrentPage, totalItem } = props;
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination" style={{ display: "flex", flexDirection: "row", listStyleType: "none", padding: 10, justifyContent: "flex-end", alignItems:"baseline"}}>
        <div onClick={() => setCurrentPage(() => (currentPage > 1 ? currentPage - 1 : currentPage))} style={{ border: "1px solid", cursor: "pointer", padding: "5px 10px" }}>
          <span>Trước</span>
        </div>
        {pageNumbers.map((number) => (
          <li onClick={() => paginate(number)} style={{ padding: "5px 10px", border: "1px solid", cursor: "pointer", margin: 3 }} key={number} className="page-item">
            <a className="page-link">{number}</a>
          </li>
        ))}
        <div onClick={() => setCurrentPage(() => (currentPage <= totalItem / itemsPerPage ? currentPage + 1 : currentPage))} style={{ border: "1px solid", cursor: "pointer", padding: "5px 10px" }}>
          <span>Sau</span>
        </div>
      </ul>
    </nav>
  );
};

export default Pagination;
