import React from "react";
import ImageButton from "../../../components/ImageButton";

export default function MainMenuButton(props: {
  text: string;
  bgImage: string;
  onClick: (e: any) => void;
}) {
  return (
    <ImageButton
      imgsrc={props?.bgImage}
      text={props?.text}
      onClick={props?.onClick}
      style={{
        containerStyle: { marginTop: -4 },
        textStyle: {
          fontSize: 13,
          top: "45%",
          left: "8%",
          transform: "translate(0, -50%)",
          backgroundImage: "linear-gradient(180deg, #FFF7E0 0%, #FEEC95 97.4%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
        },
      }}
    />
  );
}
