import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import FormOpacityModal from "../components/FormOpacityModal";
import InputText from "../components/InputText";
import Label from "../components/Label";
import { toast } from "react-toastify";
import { useLoading } from "../contexts/LoadingContext";
import { useNavigate } from "react-router-dom";
import icon_user_white from "../assets/icons/icon_user_white.svg";
import icon_phone from "../assets/icons/icon_phone.svg";
import icon_mail from "../assets/icons/icon_mail.svg";
import icon_shield from "../assets/icons/icon_shield.svg";
import CheckboxChecked from "../assets/checkbox-checked.svg";
import CheckboxEmpty from "../assets/checkbox-empty.svg";
import icon_answer from "../assets/icons/icon_answer.svg";
import icon_question from "../assets/icons/icon_question.svg";
import customAxios from "../utils/customAxios";
import InputSelect from "../components/InputSelect";
import useOtp from "../hooks/otpHook";
import useGetSercurityQuestions from "../hooks/useGetSercurityQuestions";
import "./Profile.css";
import { useCookies } from "react-cookie";
import {isDisableOTP} from "../utils/utils";

export default function EditProfile() {
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [isUseOtp, setUseOtp] = useState(true);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [otp, setOtp] = useState("");
  const removeAccentsAnswer = answer.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const [userProfile, setUserProfile] = useState<{
    name: string;
    phoneNo: string;
    email: string;
  }>();
  const { questions: listQuestion } = useGetSercurityQuestions();

  const [cookies, setCookie] = useCookies(["tlbb-account-info"]);

  const { loading, setLoading } = useLoading();
  let navigate = useNavigate();

  //states
  const {
    sendOtp,
    checkOtp,
    reset: resetOtp,
    otpSent,
    counter,
  } = useOtp(userProfile?.phoneNo || "", false);

  const submit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    if (isUseOtp) {
      if (!otp) {
        toast.error("Otp là trường bắt buộc!");
        return;
      }
    } else {
      if (!question) {
        toast.error("Câu hỏi bí mật là trường bắt buộc!");
        return;
      }
      if (!answer) {
        toast.error("Câu trả lời là trường bắt buộc!");
        return;
      }
    }
    let idToken: string | false = false;
    setLoading(true);
    if (isUseOtp) {
      if (isDisableOTP == false) {
        idToken = await checkOtp(otp);
        if (!idToken) {
          toast.error("Có lỗi xảy ra khi xác thực OTP!");
          setLoading(false);
          return;
        }
      }
    }

    try {
      const request: {
        phoneNo: string;
        email: string;
        useOtp: boolean;
        fbIdToken?: string;
        question?: string;
        answer?: string;
      } = {
        answer: answer,
        phoneNo: phoneNo,
        email: newEmail,
        useOtp: isUseOtp,
      };
      if (isUseOtp) {
        request.fbIdToken = otp || "";
      } else {
        request.question = question;
        request.answer = removeAccentsAnswer;
      }

      const resp = await customAxios.put("/user/edit-profile", request);
      setLoading(false);
      if (resp.status === 200 && resp.data?.success) {
        toast.success("Đổi thông tin nhân vật thành công!");
        setOtp("");
        resetOtp();
        updateCookie(resp.data?.data);
        setTimeout(() => navigate("/account/profile"), 1000);
      } else {
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra!");
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const updateCookie = (
    data:
      | undefined
      | {
          name: string;
          email: string;
          phoneNo: string;
        }
  ) => {
    const currentCookie = cookies?.["tlbb-account-info"];
    if (data?.name && data?.email && data?.phoneNo)
      setCookie(
        "tlbb-account-info",
        {
          ...currentCookie,
          displayName: data?.name || currentCookie?.displayName,
          email: data?.email || currentCookie?.email,
          phoneNo: data?.phoneNo || currentCookie?.phoneNo,
        },
        { path: "/", sameSite: "strict" }
      );
  };

  const getUserProfile = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const resp = await customAxios.get("/user/profile");
      setLoading(false);
      if (resp.status === 200 && resp.data?.success) {
        setUserProfile(resp.data.data);
        // setPhoneNo(resp.data.data.phoneNo);
        //setNewEmail(resp.data.data.email);
      } else {
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra!");
    }
  };

  return (
    <FormOpacityModal
      size="md"
      submit={submit}
      title="Thông tin tài khoản"
      breadcrumb={["Quản lý tài khoản", "Thông tin tài khoản"]}
      style={{ marginTop: 0 }}
    >
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "flex-start",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: 16,
              lineHeight: "28px",
              color: "#FDCC41",
            }}
          >
            Chỉnh sửa thông tin cá nhân
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <Label>Tên tài khoản</Label>
          <InputText
            imgsrc={icon_user_white}
            value={userProfile?.name || ""}
            disabled
          />
        </div>
        <div
          style={{ width: "48%", marginRight: "4%" }}
          className={"profile-sdt"}
        >
          <Label>Số điện thoại</Label>
          <InputText
            imgsrc={icon_phone}
            value={phoneNo || ""}
            onChange={(e: any) => setPhoneNo(e.target.value)}
          />
        </div>
        <div style={{ width: "48%" }} className={"profile-email"}>
          <Label>Email</Label>
          <InputText
            imgsrc={icon_mail}
            value={newEmail || ""}
            onChange={(e: any) => setNewEmail(e.target.value)}
          />
        </div>
        <div
          style={{
            borderTop: "1px solid rgba(108, 123, 136, 1)",
            width: "100%",
            height: 1,
            marginTop: 24,
            marginBottom: 24,
          }}
        />
        <div style={{ width: "100%", padding: 4 }}>
          <Label
            style={{
              marginLeft: 36,
              color: isUseOtp
                ? "rgb(255, 255, 255)"
                : "rgba(255, 255, 255, 0.45)",
            }}
          >
            Mã OTP
          </Label>
          <Label style={{
              marginLeft: 36,
              color: isUseOtp
                ? "rgb(255, 255, 255)"
                : "rgba(255, 255, 255, 0.45)",
            }}><b>TLNT sử dụng Telegram để gửi OTP</b>. Vui lòng xem hướng dẫn lấy mã OTP bằng ứng dụng Telegram tại <a href="https://thienlongngutuyet.net/huong-dan-su-dung-otp-telegram/" style={{color: "white"}} target="_blank"><b>đây</b></a>.</Label>
          <Label style={{
              marginLeft: 36, 
              color: isUseOtp
                ? "rgb(255, 255, 255)"
                : "rgba(255, 255, 255, 0.45)",
            }}><i>Bấm vào nút <b>Nhận OTP</b> bên dưới để mở Telegram</i></Label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              alt=""
              src={isUseOtp ? CheckboxChecked : CheckboxEmpty}
              width={24}
              height={24}
              style={{ marginRight: 12, cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setUseOtp(true);
              }}
            />
            <div
              style={{
                flex: 1,
                position: "relative",
              }}
            >
              <InputText
                imgsrc={icon_shield}
                disabled={!isUseOtp}
                type="text"
                value={otp}
                onChange={(e: any) => setOtp(e?.target?.value)}
              />
              <button
                type="button"
                style={{
                  position: "absolute",
                  right: 12,
                  top: "50%",
                  cursor: counter > 0 ? "unset" : "pointer",
                  transform: "translate(0, -50%)",
                  padding: "4px 8px",
                  fontSize: 11,
                  background: counter > 0 ? "#717171" : "rgb(39, 174, 96)",
                  border: "1px solid rgba(255, 255, 255, 0.45)",
                  borderRadius: 4,
                  color: "#ddd",
                  opacity: !isUseOtp ? 0.6 : 1,
                }}
                disabled={counter > 0 || !isUseOtp}
                onClick={() => window.open("https://t.me/ThienLongNguTuyetBot", '_blank', 'noopener,noreferrer')}
              >
                {otpSent
                  ? "Gửi lại" + (counter > 0 ? ` (${counter})` : "")
                  : "Nhận OTP"}
              </button>
            </div>
          </div>

          <div id="recaptcha-container"></div>
        </div>
        <div style={{ width: "50%", padding: 4 }} className={"profile-sdt"}>
          <Label
            style={{
              marginLeft: 36,
              color: !isUseOtp
                ? "rgb(255, 255, 255)"
                : "rgba(255, 255, 255, 0.45)",
            }}
          >
            Câu hỏi bí mật
          </Label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              alt=""
              src={!isUseOtp ? CheckboxChecked : CheckboxEmpty}
              width={24}
              height={24}
              style={{ marginRight: 12, cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setUseOtp(false);
              }}
            />
            <div
              style={{
                flex: 1,
              }}
            >
              <InputSelect
                disabled={isUseOtp}
                imgsrc={icon_question}
                value={question}
                onChange={(e: any) => setQuestion(e.target.value)}
                style={{
                  width: "100%",
                  backgroundColor: isUseOtp
                    ? "rgb(50, 55, 61)"
                    : "rgb(255, 255, 255)",
                }}
              >
                <option value="">Chọn câu hỏi bí mật</option>
                {listQuestion.map((q, index) => (
                  <option key={q.qsId} value={q.qsId}>
                    {q.qsText}
                  </option>
                ))}
              </InputSelect>
            </div>
          </div>
        </div>
        <div style={{ width: "50%", padding: 4 }} className={"profile-sdt"}>
          <Label
            style={{
              color: !isUseOtp
                ? "rgb(255, 255, 255)"
                : "rgba(255, 255, 255, 0.45)",
            }}
          >
            Câu trả lời
          </Label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <InputText
                imgsrc={icon_answer}
                disabled={isUseOtp}
                value={answer}
                type="text"
                onChange={(e: any) => setAnswer(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            borderTop: "1px solid transparent",
            width: "100%",
            height: 1,
            marginTop: 24,
            marginBottom: 24,
          }}
        />

        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div>
            <Button onClick={submit} size="md">
              Thay đổi thông tin
            </Button>
          </div>
          <div style={{ marginLeft: 10 }}>
            <Button
              type="button"
              onClick={() => navigate(-1)}
              style={{
                background: "#DD3D3D",
                padding: "6px 8px",
              }}
              size="xs"
            >
              Hủy
            </Button>
          </div>
        </div>
      </div>
    </FormOpacityModal>
  );
}
