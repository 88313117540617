import React from "react";
import ImageButton from "../../../components/ImageButton";
import rechargeBg from "../../../assets/LeftNav/recharge-background.png";
import rechargeBtn from "../../../assets/LeftNav/recharge-btn.png";
import { useNavigate } from "react-router-dom";
import DownloadButton from "./DownloadButton";

export default function HeaderButtons() {
  let navigate = useNavigate();
  return (
    <>
      <DownloadButton />
      <div
        style={{
          backgroundImage: `url(${rechargeBg})`,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          padding: 8,
        }}
      >
        <ImageButton
          onClick={() => {
            navigate("/account/recharge");
          }}
          imgsrc={rechargeBtn}
          text=""
          style={{ containerStyle: { display: "flex" } }}
          hilghtOnHover
        />
      </div>
    </>
  );
}
