import React from "react";
import "./ImageButton.css";

export default function ImageButton(props: {
  imgsrc: string;
  text: string;
  onClick?: any;
  style?: {
    containerStyle?: React.CSSProperties | undefined;
    textStyle?: React.CSSProperties | undefined;
    imgStyle?: React.CSSProperties | undefined;
  };
  hilghtOnHover?: boolean;
}) {
  const classNames = [
    "img-btn",
    props?.hilghtOnHover ? "img-btn-highlight" : undefined,
  ];
  return (
    <div
      className={classNames.join(" ")}
      onClick={(e) => {
        e.preventDefault();
        props?.onClick && props.onClick(e);
      }}
      style={props?.style?.containerStyle}
    >
      <img
        src={props?.imgsrc}
        style={{
          ...{ width: "100%", pointerEvents: "none" },
          ...props?.style?.imgStyle,
        }}
        alt="btn"
      />
      {props?.text && (
        <div
          style={{
            ...{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "inline",
              fontWeight: "bolder",
            },
            ...props?.style?.textStyle,
          }}
        >
          {props?.text}
        </div>
      )}
    </div>
  );
}
