import { useEffect, useState } from "react";
import customAxios from "../utils/customAxios";

const useGetSercurityQuestions = (useHardcode = true) => {
  const [questions, setQuestions] = useState<
    {
      qsId: number;
      qsText: string;
      qsStatus: number;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const setHardcodeQuestions = () => {
    setQuestions([
      {
        qsId: 1,
        qsText: "Người mà bạn thích nhất?",
        qsStatus: 1,
      },
      {
        qsId: 2,
        qsText: "Cuốn sách mà bạn thích nhất?",
        qsStatus: 1,
      },
      {
        qsId: 3,
        qsText: "Bộ phim mà bạn thích nhất?",
        qsStatus: 1,
      },
      {
        qsId: 4,
        qsText: "Diễn viên mà bạn thích nhất?",
        qsStatus: 1,
      },
      {
        qsId: 5,
        qsText: "Ca khúc mà bạn thích nhất?",
        qsStatus: 1,
      },
      {
        qsId: 6,
        qsText: "Nơi mà bạn sinh ra?",
        qsStatus: 1,
      },
      {
        qsId: 7,
        qsText: "Biển số xe của bạn là gì?",
        qsStatus: 1,
      },
    ]);
  };

  const getSecurityQuestion = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const resp = await customAxios.get("/config/questions");
      setLoading(false);
      if (resp.status === 200 && resp.data?.success) {
        setQuestions(resp.data.data);
      } else {
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        setError(message);
      }
    } catch (error) {
      setLoading(false);
      setError("Có lỗi xảy ra!");
    }
  };

  useEffect(() => {
    if (useHardcode) {
      //use hardcode question for fast load
      setHardcodeQuestions();
    } else {
      getSecurityQuestion();
    }
  }, []);

  return { questions, loading, error };
};

export default useGetSercurityQuestions;
