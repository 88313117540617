import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import FormOpacityModal from "../components/FormOpacityModal";
import InputText from "../components/InputText";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputSelect from "../components/InputSelect";
import icon_user from "../assets/icons/icon_user.svg";
import icon_lock from "../assets/icons/icon_lock.svg";
import icon_lock2 from "../assets/icons/icon_lock2.svg";
import icon_phone from "../assets/icons/icon_phone.svg";
import icon_mail from "../assets/icons/icon_mail.svg";
import icon_question from "../assets/icons/icon_question.svg";
import icon_answer from "../assets/icons/icon_answer.svg";
import customAxios from "../utils/customAxios";
import lg_big from "../assets/mainModal/lg-big.png";
import useGetSercurityQuestions from "../hooks/useGetSercurityQuestions";
import useGetServerList from "../utils/ServerList";
import { useLoading } from "../contexts/LoadingContext";
import icon_shield from "../assets/icons/icon_shield.svg";
import useOtp from "../hooks/otpHook";
import {isDisableOTP} from "../utils/utils";
import {
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";
import CheckboxChecked from "../assets/checkbox-checked.svg";
import CheckboxEmpty from "../assets/checkbox-empty.svg";
import icon_eyepassword from "../assets/icons/eye-slash.svg";
import icon_eyepasswordclose from "../assets/icons/eye.svg";

export default function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = (e:any) => {
    e.preventDefault();
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setShowPassword(!showPassword);
  };
  const [phoneNo, setPhoneNo] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [otp, setOtp] = useState("");
  const { loading, setLoading } = useLoading();
  const { questions: listQuestion } = useGetSercurityQuestions();
  const { serverList: serverList, defaultSelected } = useGetServerList();
  const [server, setServer] = useState(defaultSelected);

  const [registerStep, setRegisterStep] = useState(1);
  
  const {
    sendOtp,
    checkOtp,
    reset: resetOtp,
    otpSent,
    counter,
  } = useOtp(phoneNo);

  let navigate = useNavigate();

  const [verificationType, setVerificationType] = useState<1 | 2>(1);

  const verifyByPhoneNo = async () => {
    if (!username || username.length < 6) {
      toast.error("Tài khoản phải có từ 7 ký tự trở lên!");
      return;
    }

    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email || !email.match(validRegex)) {
      toast.error("Email không hợp lệ!");
      return;
    }

    setPhoneNo(phoneNo.trim());
    const phoneRegex = /^[0-9]*$/;
    if (!phoneNo || phoneNo.length != 10 || !phoneNo.match(phoneRegex)) {
      toast.error("Số điện thoại chỉ gồm số và có 10 ký tự!");
      return;
    }
    if (!answer || answer.length < 6) {
      toast.error("Câu trả lời bí mật phải có từ 6 ký tự trở lên!");
      return;
    }   
    if (!password || password.length < 6) {
      toast.error("Mật khẩu phải có từ 6 ký tự trở lên!");
      return;
    }
    if (!question || question.length < 1 || question.length > 7) {
      toast.error("Câu hỏi bí mật không hợp lệ!");
      return;
    }
    if (!confirmPassword || confirmPassword !== password) {
      toast.error("Mật khẩu và xác nhận mật khẩu không trùng nhau!!");
      return;
    }

    if (isDisableOTP == true) {
      await submit(undefined);
    } else {
      sendOtp();
      setRegisterStep(2);
    }
    
    // setMsgInstruction(
    //   `*OTP đã được gửi về số điện thoại ${phoneNo}, vui lòng kiểm tra OTP`
    // );
  };

  const nextStep = (e: any) => {
    if (verificationType === 1) {
      e?.preventDefault();
      verifyByPhoneNo();
    } else if (verificationType === 2) {
      verifyByEmail(e);
    }
  };

  const verifyByEmail = async (e: any) => {
    e?.preventDefault();
    try {
      setLoading(true);

      const res = await customAxios.post(
        "/authentication/register-unconfirmed-email",
        {
          username,
          password,
          phoneNo,
          email,
          question,
          answer,
          server
        }
      );

      if (res.status === 200 && res.data.success) {
        toast.success("Đăng ký thành công!");
        //goi form view thong tin
        setLoading(false);
        setRegisterStep(3);
      } else {
        setLoading(false);
        toast.error(
          res.data?.messageList?.length
            ? res.data?.messageList[0]
            : "Có lỗi xảy ra!"
        );

        setPassword("");
        setConfirmPassword("");
        setRegisterStep(1);
      }
    } catch (error) {
      setLoading(false);
      toast.warning("Có lỗi xảy ra");
      setRegisterStep(1);
    }
  };

  const submit = async (e: any) => {
    e?.preventDefault();
    try {
      setLoading(true);
      const idToken = ""
      if (isDisableOTP == false) {
        const idToken = await checkOtp(otp);
        if (!idToken) {
          setLoading(false);
          toast.error("Mã OTP không hợp lệ!");
          return;
        }
      }    
      const removeAccentsAnswer = answer.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      
      const res = await customAxios.post("/authentication/register", {
        username,
        password,
        phoneNo,
        email,
        question,
        answer:removeAccentsAnswer,
        server,
        fbIdToken: idToken.toString(),
      });

      setLoading(false);
      if (res.status === 200 && res.data.success) {
        toast.success("Đăng ký thành công!");
        //goi form view thong tin
        setLoading(false);
        setRegisterStep(4);
        //navigate("/login");
      } else {
        toast.error(
          res.data?.messageList?.length
            ? res.data?.messageList[0]
            : "Có lỗi xảy ra!"
        );

        // setUsername("");
        setPassword("");
        setConfirmPassword("");
        setRegisterStep(1);
        // setPhoneNo("");
        // setEmail("");
        // setAnswer("");
      }
    } catch (error) {
      setLoading(false);
      toast.warning("Có lỗi xảy ra");
      setRegisterStep(1);
    }
  };
  const App = () => {
    // Giả sử có một biến passwordFromApi chứa giá trị mật khẩu từ API
    //const passwordFromApi = 'MậtKhẩuTừAPI';
  
    // Sử dụng useState với giá trị ban đầu là passwordFromApi
    //const [password, setPassword] = useState(passwordFromApi);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  
    const toggleContent = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          alt="logo"
          src={lg_big}
          style={{ margin: "0 auto", display: "flex", width: 150 }}
        ></img>
      </div>
      <FormOpacityModal title="Đăng ký tài khoản" submit={nextStep}>
        {registerStep == 1 && (
          <>
            <p style={{ fontSize: 13, fontStyle: "italic" }}>
              *Nhằm đảm bảo cho tài khoản, hãy nhập chính xác thông tin bên dưới
            </p>
            <div style={{ width: "auto" }}>
              <label>Tên tài khoản</label>
              <InputText
                imgsrc={icon_user}
                value={username}
                name="username"
                onChange={(e: any) => setUsername(e.target.value)}
                required
              />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 10 }}>
                  <label>Email</label>
                  <InputText
                    imgsrc={icon_mail}
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div style={{ content: "right" }}>
                  <label>Số điện thoại</label>
                  <InputText
                    imgsrc={icon_phone}
                    value={phoneNo}
                    onChange={(e: any) => setPhoneNo(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 10 }}>
                  <label>Mật khẩu</label>
                  <InputText
                    type="password"
                    name="password"
                    imgsrc={icon_lock}
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label>Xác nhận mật khẩu</label>
                  <InputText
                    type="password"
                    name="repassword"
                    imgsrc={icon_lock2}
                    value={confirmPassword}
                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <label>Câu hỏi bảo mật</label>
              {/* <div style={{ marginTop: 8, marginBottom: 8, position: "relative" }}> */}

              <InputSelect
                imgsrc={icon_question}
                value={question}
                onChange={(e: any) => setQuestion(e.target.value)}
                style={{ width: "100%" }}
                required
              >
                <option value="">Chọn câu hỏi bí mật</option>
                {listQuestion.map((q, index) => (
                  <option key={q.qsId} value={q.qsId}>
                    {q.qsText}
                  </option>
                ))}
              </InputSelect>
              {/* </div> */}
              <label>Câu trả lời</label>
              <InputText
                imgsrc={icon_answer}
                value={answer}
                onChange={(e: any) => setAnswer(e.target.value)}
                required
              />
              <label>Chọn máy chủ</label>
              {/* <div style={{ marginTop: 8, marginBottom: 8, position: "relative" }}> */}

              <InputSelect
                imgsrc={icon_question}
                value={server}
                onChange={(e: any) => setServer(e.target.value)}
                style={{ width: "100%" }}
                required
              >
                <option value="">Chọn máy chủ muốn chơi</option>
                {serverList.map((q, index) => (
                  <option key={q.qsId} value={q.qsId}>
                    {q.qsText}
                  </option>
                ))}
              </InputSelect>
              <label style={{ display: "none" }}>Chọn hình thức xác minh:</label>
              <div style={{ display: "none", margin: "8px 4px" }}>
                <img
                  alt=""
                  src={verificationType == 1 ? CheckboxChecked : CheckboxEmpty}
                  width={24}
                  height={24}
                  style={{ marginRight: 12, cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setVerificationType(1);
                  }}
                />
                <label
                  onClick={(e) => {
                    e.preventDefault();
                    setVerificationType(1);
                  }}
                >
                  Số điện thoại
                </label>
              </div>
              <div style={{ display: "none", margin: "8px 4px" }}>
                <img
                  alt=""
                  src={verificationType == 2 ? CheckboxChecked : CheckboxEmpty}
                  width={24}
                  height={24}
                  style={{ marginRight: 12, cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setVerificationType(2);
                  }}
                />
                <label
                  onClick={(e) => {
                    e.preventDefault();
                    setVerificationType(2);
                  }}
                >
                  Email
                </label>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 12,
              }}
            >
              {errMsg ? (
                <p style={{ backgroundColor: "red" }}>{errMsg} không đúng!!</p>
              ) : (
                ""
              )}

              <Button
                onClick={nextStep}
                style={{ width: 256 }}
                disabled={counter > 0}
                type="button"
              >
                Đăng ký
              </Button>
            </div>
            <p>
              Đã có tài khoản tại Thiên Long Ngũ Tuyệt?{" "}
              <Link
                to="/login"
                style={{ color: "#F78143", textDecoration: "none" }}
              >
                Đăng nhập ngay
              </Link>
            </p>
          </>
        )}
        {registerStep == 2 && (
          <>
            <p style={{ fontSize: 13, fontStyle: "italic" }}>
              *OTP đã được gửi về số điện thoại ${phoneNo}, vui lòng kiểm tra
              OTP
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "stretch",
              }}
            >
              <div style={{ width: "100%" }}>
                <label>Mã OTP</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      position: "relative",
                    }}
                  >
                    <InputText
                      imgsrc={icon_shield}
                      value={otp}
                      type="text"
                      onChange={(e: any) => setOtp(e.target.value)}
                    />
                    <button
                      type="button"
                      style={{
                        position: "absolute",
                        right: 12,
                        top: "50%",
                        transform: "translate(0, -50%)",
                        cursor: counter > 0 ? "unset" : "pointer",
                        padding: "4px 8px",
                        fontSize: 11,
                        background:
                          counter > 0 ? "#717171" : "rgb(39, 174, 96)",
                        border: "1px solid rgba(255, 255, 255, 0.45)",
                        borderRadius: 4,
                        color: "#ddd",
                      }}
                      disabled={counter > 0}
                      onClick={sendOtp}
                    >
                      {otpSent
                        ? "Gửi lại" + (counter > 0 ? ` (${counter})` : "")
                        : "Gửi OTP"}
                    </button>
                  </div>style
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 12,
              }}
            >
              {errMsg ? (
                <p style={{ backgroundColor: "red" }}>{errMsg} không đúng!!</p>
              ) : (
                ""
              )}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  // onClick={nextStep}
                  // style={{ width: 256 }}
                  disabled={loading}
                  type="button"
                  onClick={submit}
                >
                  Đăng ký
                </Button>
                <div style={{ marginLeft: 10 }}>
                  <Button
                    type="button"
                    onClick={() => setRegisterStep(1)}
                    style={{
                      background: "#DD3D3D",
                      padding: "6px 8px",
                    }}
                    size="md"
                  >erStep ==
                    Quay lại
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        {registerStep == 3 && (
          <>
              
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "stretch",
                }}
              >
                <label>
                  *Đã gửi mail xác thực, vui lòng kiểm tra hòm mail {email}, kiểm
                  tra trong hòm thư rác nếu không thấy mail xác thực, nếu sau 30
                  phút không nhận được mail xác thực vui lòng liên hệ fanpage để
                  được hỗ trợ!
                
                <p><span>----------------------------------------------------------</span></p>
                <p><span > Tên đăng nhập : {username} </span></p>
                <p><span> Số điện thoại : {phoneNo} </span></p>             
                <p><span> Mật khẩu : </span>{showPassword ? password : '*****'}  <img src={showPassword ?icon_eyepassword:icon_eyepasswordclose} alt={showPassword ? 'Ẩn mật khẩu' : 'Hiện mật khẩu'}
                     style={{ cursor: 'pointer', marginLeft: '5px' }}
                     onClick={togglePassword}
                /></p>
               
                
                <p><span> Email : {email} </span></p>
                </label>
                
                <div style={{ display: 'flex', alignItems: 'center' }}>
         
        </div>
                
              </div>
          </>
        )}
      {registerStep == 4 && (
          <>
              
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "stretch",
                }}
              >
                <label>
                 
                <p><span > Tên đăng nhập : {username} </span></p>
                <p><span> Số điện thoại : {phoneNo} </span></p>             
                <p><span> Mật khẩu : </span>{showPassword ? password : '*****'}  <img src={showPassword ?icon_eyepassword:icon_eyepasswordclose} alt={showPassword ? 'Ẩn mật khẩu' : 'Hiện mật khẩu'}
                     style={{ cursor: 'pointer', marginLeft: '5px' }}
                     onClick={togglePassword}
                /></p>
               
                
                <p><span> Email : {email} </span></p>
                </label>
                
                <div style={{ display: 'flex', alignItems: 'center' }}>
         
        </div>
                
              </div>
          </>
        )}
        <div id="recaptcha-container"></div>
      </FormOpacityModal>
    </div>
  );
}
