import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Profile from "./screens/Profile";
import Login from "./screens/Login";
import Register from "./screens/Register";
import ChangePassword from "./screens/ChangePassword";
import Account from "./layouts/Account";
import NotFound from "./screens/NotFound";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { LoadingProvider } from "./contexts/LoadingContext";
import LoadingIndicator from "./components/LoadingIndicator";
import EditProfile from "./screens/EditProfile";
import ForgotPassword from "./screens/ForgotPassword";
import Recharge from "./screens/Recharge";
import { useCookies } from "react-cookie";
import TransactionHistory from "./screens/TransactionHistory";
import ChangeQuestion from "./screens/ChangeQuestion";
import CompleteEmailConfirmRegister from "./screens/CompleteEmailConfirmRegister";

export default function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["tlbb-account-info"]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    setIsLoggedIn(!!cookies["tlbb-account-info"]?.token);
  }, [cookies?.["tlbb-account-info"]?.token]);
  return (
    <LoadingProvider>
      <div className="App">
        <LoadingIndicator />
        <ToastContainer position="top-right" autoClose={3000} toastStyle={{ color: "#000" }} hideProgressBar limit={3} />
        <Routes>
          <Route element={<Account />}>
            {/* Protected routes, những tài khoản đã login mới có thể xem đc những trang này */}
            <Route index element={<Profile />} />
            <Route path="/account/profile" element={<Profile />} />
            <Route path="/account/recharge" element={<Recharge />} />
            <Route path="/account/edit-profile" element={<EditProfile />} />
            <Route path="/account/recharge-history" element={<TransactionHistory />} />
            <Route path="/account/change-password" element={<ChangePassword />} />
            <Route path="/account/change-question" element={<ChangeQuestion />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/complete-email-confirm-register" element={<CompleteEmailConfirmRegister />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/:nonsense" element={<Navigate to="/not-found" />} />
        </Routes>
      </div>
    </LoadingProvider>
  );
}
