import React from "react";
import { Routes, Route, Link, Outlet } from "react-router-dom";

export default function NotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2 style={{ color: "white" }}>Not Found</h2>
    </div>
  );
}
