import React from "react";

export default function Label(props: any) {
  const { fullWidth = true, style, ...otherProps } = props;
  return (
    <label
      style={{
        ...{
          display: fullWidth ? "block" : "inline",
          fontSize: 14,
          lineHeight: "24px",
          color: "white",
          marginBottom: fullWidth ? 8 : 0,
        },
        ...style,
      }}
      {...otherProps}
    ></label>
  );
}
