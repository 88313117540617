import React, { useCallback, useEffect, useState } from "react";
import Pagination from "./Pagination";
import icon_question from "../assets/icons/icon_search.svg";
import { toast } from "react-toastify";
import customAxios from "../utils/customAxios";
import { useLoading } from "../contexts/LoadingContext";
import FormOpacityModal from "../components/FormOpacityModal";
import noData from "../assets/icons/no-data.png"

export default function TransactionTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [transactions, setTransactions] = useState<
    {
      id: number;
      amount: number;
      point: number;
      bonusPoint: number;
      code: string;
      date: Date;
    }[]
  >([]);
  const { loading, setLoading } = useLoading();

  const getTransactionHistory = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const resp = await customAxios.get("/user/transaction-history");
      setLoading(false);
      if (resp.status === 200 && resp.data?.success) {
        setTransactions(
          resp.data.data?.map((x: any) => ({ ...x, date: new Date(x.date) }))
        );
      } else {
        const message = resp.data?.messageList?.length
          ? resp.data?.messageList[0]
          : "Có lỗi xảy ra!";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra!");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getTransactionHistory();
    }, 0);
  }, []);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItem = transactions.slice(indexOfFirstItem, indexOfLastItem);
  const totalItems = transactions.length;

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const total = transactions?.reduce((prev, curr) => prev + curr.amount, 0);

  const [searchValue, setSearchValue] = useState("");

  const searchCodeHandle = (e: any) => {
    e.preventDefault();
    setCurrentPage(1);
    if (searchValue) {
      const result = transactions.filter((t) => {
        return t.code === searchValue;
      });
      setTransactions(result);
    } else {
      getTransactionHistory();
    }
  };

  const formatDate = useCallback((date: Date) => {
    {
      return `${date.getDate().toString().padStart(2, "0")}/
        ${(date.getMonth() + 1).toString().padStart(2, "0")}/
        ${date.getFullYear().toString().slice(2, 4)} 
        ${date.getHours().toString().padStart(2, "0")}:
        ${date.getMinutes().toString().padStart(2, "0")}`;
    }
  }, []);

  return (
    <FormOpacityModal
      size="lg"
      title="Lịch sử nạp thẻ"
      breadcrumb={["Quản lý tài khoản", "Lịch sử nạp thẻ"]}
      style={{ marginTop: 0 }}
      submit={searchCodeHandle}
    >
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "start",
        }}
      >
        <div
          style={{
            marginBottom: 8,
            justifyContent: "flex-end",
            position: "relative",
            display: "flex",
            width: "100%",
          }}
        >
          <div style={{ position: "relative", width: "50%" }}>
            <img
              onClick={searchCodeHandle}
              style={{
                height: 20,
                width: 20,
                position: "absolute",
                boxSizing: "border-box",
                top: "50%",
                left: 10,
                transform: "translateY(-50%)",
              }}
              src={icon_question}
              alt=""
            ></img>
            <input
              style={{
                backgroundColor: "#d4d4d4",
                display: "block",
                width: "100%",
                boxSizing: "border-box",
                padding: "6px 12px",
                border: "1px solid rgba(34, 49, 63, 0.3)",
                borderRadius: 4,
                height: 40,
                color: "black",
                paddingLeft: 35,
              }}
              onChange={(e: any) => setSearchValue(e.target.value)}
              placeholder="Tìm kiếm theo mã giao dịch"
            ></input>
          </div>
        </div>
        <div style={{ marginBottom: 10 }}>
          Tổng số tiền nạp:{" "}
          <span style={{ color: "rgb(255, 255, 198)" }}>
            {total.toLocaleString()}
          </span>{" "}
          VNĐ
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#F0562B" }}>
              <th>Ngày nạp</th>
              <th>VND</th>
              <th>Bạc</th>
              <th>Bạc KM</th>
              <th>Mã GD</th>
            </tr>
          </thead>
          <tbody>
            {currentItem.map((t, index) => (
              <tr key={t.id}>
                <td>{formatDate(t.date)}</td>
                <td>{t.amount.toLocaleString()}</td>
                <td>{t.point.toLocaleString()}</td>
                <td>{t.bonusPoint.toLocaleString()}</td>
                <td>{t.code}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {
          transactions.length === 0 ? (
            <div style={{
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",

            }}>
              <div style={{
                margin: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

              }}>
                <img
                  src={noData}
                  alt="no-data"
                  width={"50%"}
                />
              </div>
              <div>
                <span style={{ color: "#d43831", fontSize: '17px' }}>Không Có Lịch Sử Giao Dịch</span>
              </div>

            </div>
          ) : null
       }
       
        <div>
          <Pagination
            itemsPerPage={itemPerPage}
            totalItems={transactions.length}
            paginate={paginate}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalItem={totalItems}
          />
        </div>
      </div>
    </FormOpacityModal>
  );
}
